import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../element/graphical/Logo";
import NavigationMobile from "./NavigationMobile";
import { ooContentWidth } from "../../element/theme";
import DesktopNavigation from "./NavigationDesktop";
import UserButton from "./UserButton";
// import LoginModal from "../../util/unfinished-obsolete/login/LoginModal";


const useStyles = makeStyles(theme => ({
  root: {
    margin: "0 auto",
    maxWidth: `${ooContentWidth}rem`, // max-width
    width: "100%",
  },
  colorDefault: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },
  desktopNav: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingLeft: "2rem",
    },
  },
  mobileNav: {
    display: "flex",
    marginRight: "0.5rem",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userButton: {
    "@media (max-width: 424px)": {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <nav className={classes.root}>
      <AppBar position="static" className={classes.colorDefault}>
        <Toolbar>

          <div className={classes.mobileNav}>
            <NavigationMobile />
          </div>
          <Logo />
          <div className={classes.desktopNav}>
            <DesktopNavigation />
          </div>

          <div className={classes.grow} />

          {/*<LoginModal /> */}
          <div className={classes.userButton}>
            <UserButton />
          </div>

        </Toolbar>
      </AppBar>
    </nav>
  );
};

export default Header;
