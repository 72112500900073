import React from "react";
import { Typography } from "@material-ui/core";
import { Link } from "gatsby";
import styled from "styled-components";
import { FooterLink } from "./Footer"; // eslint-disable-line import/no-cycle
import theme from "../../element/theme";
import { ConciseList } from "../../element/page-element/ConciseList";

export const ConciseLegalNotice = styled.div`
  margin: 1rem auto 0 auto;
  color: ${theme.palette.text.disabled};
  max-width: 30rem;
  text-align: left;
  padding-left: 1.5rem;
`;

const NewsletterLegalText = () => {
  const mailchimpPrivacyPolicy = (
    <FooterLink href="https://mailchimp.com/legal/privacy" target="_blank" rel="noopener">
      privacy policy
    </FooterLink>
  );
  const googlePrivacyPolicy = (
    <FooterLink href="https://policies.google.com/privacy" target="_blank" rel="noopener">
      privacy policy
    </FooterLink>
  );
  const googleTerms = (
    <FooterLink href="https://policies.google.com/terms" target="_blank" rel="noopener">
      terms of service
    </FooterLink>
  );
  const oberionPrivacyPolicy = (
    <FooterLink as={Link} to="/privacy">privacy practices</FooterLink>
  );

  return (
      <ConciseLegalNotice>
        <ConciseList>
          <li>
            <Typography variant="caption">
              You receive one newsletter per month.
              Your email is only used for this purpose.
              Learn more about Oberion&apos;s {oberionPrivacyPolicy}.
            </Typography>
          </li>
          <li>
            <Typography variant="caption">
              Email service Mailchimp is used for managing the newsletter and processing your
              mail address. See Mailchimp&apos;s {mailchimpPrivacyPolicy}.
            </Typography>
          </li>
          <li>
            <Typography variant="caption">
              Unsubscribe any time by clicking the link in the newsletter&apos;s footer.
            </Typography>
          </li>
        </ConciseList>
      </ConciseLegalNotice>
  );
};

export default NewsletterLegalText;
