import React from "react";
import * as Sentry from "@sentry/browser";
import * as PropTypes from "prop-types";
import BadRequest400 from "./BadRequest400";

/**
 * Log errors using sentry.io and React Error Boundaries.
 * @see https://sentry.io
 * @see https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html#introducing-error-boundaries
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      /* --- render fallback UI --- */
      return <BadRequest400 />;
    }
    /* --- no error => render children untouched --- */
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};