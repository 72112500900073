import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Link as MuiLink } from "@material-ui/core";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import theme, { fontWeightMedium, linkViolet } from "../element/theme";

export const ROUTES = {
  BASE_URL: "https://oberion.io",
  HOME: {
    CAPTION: "Home",
    TO: "/",
  },
  ABOUT: {
    CAPTION: "About",
    TO: "/about",
  },
  BLOG: {
    CAPTION: "Blog",
    TO: "/blog",
  },
  BACKLOG_CHALLENGE: {
    CAPTION: "Backlog Challenge",
    TO: "/backlog-challenge",
  },
  NEWSLETTER: {
    CAPTION: "Newsletter",
    TO: "/#newsletter",
  },
  LEGAL: {
    CAPTION: "Legal Notice - Impressum",
    TO: "/legal",
  },
  LIBRARY: {
    CAPTION: "Library",
    TO: "/library-preview",
  },
  LISTS: {
    CELESTE: {
      CAPTION: "Celeste",
      TO: "/lists/celeste",
    },
  },
  PRIVACY: {
    CAPTION: "Privacy Policy",
    TO: "/privacy",
  },
  RSS: {
    CAPTION: "RSS",
    TO: "/rss.xml",
  },
  SETTINGS_ACCOUNT: {
    CAPTION: "Settings and Privacy",
    TO: "/settings/account",
  },
  STEAM_LOGIN: {
    CAPTION: "Log in via Steam",
    TO: "/steam-login",
    WELCOME: "/steam-login/#welcome",
    LOGOUT: "/steam-login/#logged-out",
  },
  THANKS: {
    CAPTION: "Thanks",
    TO: "/thanks",
  },
  TOUR: {
    CAPTION: "Discovery Tour",
    TO: "/tour",
  },
};

/**
 * Removes link styles for a link (GatsbyLink) intended for internal routing. Useful for button or icon links.
 */
export const ButtonRoute = styled(GatsbyLink)`
  text-decoration: none;
  color: inherit;
`; // TODO use material UI buttons <Button component={GatsbyLink} to={ROUTES.KONTAKT.TO}>

export const darkThemedLink = makeStyles({
  link: {
    color: theme.palette.secondary.main,
    "&:hover": { color: theme.palette.text.hint, cursor: "pointer" },
    "&:active": { color: theme.palette.text.hint },
    // "&:visited": { color: blue3 },
  },
});
export const lightThemedLink = makeStyles({
  link: {
    fontWeight: fontWeightMedium,

    color: linkViolet,
    "&:hover": { color: theme.palette.text.hint, cursor: "pointer" },
    "&:active": { color: theme.palette.text.hint },
    // "&:visited": { color: purple1 },
  },
});


/**
 * Provides an Oo-themed link (GatsbyLink) intended for internal routing.<br>
 * @param caption     Required. The link / route text to display.<br>
 * @param to          Required. The internal route.<br>
 * @param variant     Optional. MUI Typography variant to style such as body1. Default: inherit.<br>
 * @param lightTheme  Optional. Dark or light themed routes. Default: Dark.<br>
 */
export const InternalRoute = ({ caption, to, variant, lightTheme }) => {
  let classes = null;
  if (lightTheme === true) {
    classes = lightThemedLink();
  } else {
    classes = darkThemedLink();
  }

  const { link } = classes;
  return (
    <MuiLink
      component={GatsbyLink}
      to={to}
      variant={variant}
      underline="hover"
      className={link}
    >
      {caption}
    </MuiLink>
  );
};
InternalRoute.defaultProps = {
  variant: "inherit",
  lightTheme: false,
};
InternalRoute.propTypes = {
  caption: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  variant: PropTypes.string,
  lightTheme: PropTypes.bool,
};

/**
 * Provides an Oo-themed link intended for button like link text. Useful for showing modals or triggering events.<br>
 *   <br>
 * @param href        Required. The external link to direct to.<br>
 * @param caption     Required. The link / route text to display.<br>
 * @param variant     Optional. MUI Typography variant to style. Default: inherit.<br>
 * @param lightTheme  Optional. Dark or light themed routes. Default: Dark.<br>
 */
export const ThemedLink = ({ caption, variant, lightTheme }) => {
  let classes = null;

  if (lightTheme === true) classes = lightThemedLink();
  else classes = darkThemedLink();
  const { link } = classes;

  return (
    <MuiLink
      variant={variant}
      underline="hover"
      className={link}
      component="a"
    >
      {caption}
    </MuiLink>
  );
};
ThemedLink.defaultProps = {
  variant: "inherit",
  lightTheme: false,
};
ThemedLink.propTypes = {
  caption: PropTypes.string.isRequired,
  variant: PropTypes.string,
  lightTheme: PropTypes.bool,
};
