import React, { useContext } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonRoute, ROUTES } from "../InternalRoute";
import { AuthContext } from "../../authentication/AuthContextProvider";
import UserProfileButton from "./UserProfileButton";

const useStyles = makeStyles(theme => ({
  avatar: {
    textAlign: "right",
    color: "inherit",
    backgroundColor: theme.palette.primary.main,
    margin: "0.325rem 1.25rem",
    padding: "1.5rem",
  },
  buttonSteam: {
    padding: "0.5rem 1rem",
    marginLeft: "0.3rem",
    textTransform: "none",
  },
  buttonAvatar: {
    padding: "0",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const UserButton = () => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);

  let userButton = null;
  if (authContext.isLoggedIn()) {
    userButton = (
      <UserProfileButton />
    );
  } else {
    userButton = (
      <ButtonRoute as={GatsbyLink} to={ROUTES.STEAM_LOGIN.TO}>
        <Button variant="outlined" color="secondary" className={classes.buttonSteam} size="large">
          {ROUTES.STEAM_LOGIN.CAPTION}
        </Button>
      </ButtonRoute>
    );
  }
  return userButton;
};

export default UserButton;