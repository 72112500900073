import React from "react";
import * as PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../theme";
import "../../util/global-styles.css";
import { AuthContextProvider } from "../../authentication/AuthContextProvider";
import ErrorBoundary from "../../util/error-handling/ErrorBoundary";
import favicon from "../../../res/branded/favicon.svg";

export default function RootLayout({ children }) {
  return (
    <>
      <Helmet>
        <meta name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
        <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600&display=swap" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContextProvider>
            {children}
          </AuthContextProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
}

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
