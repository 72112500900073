import React from "react";
import * as PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import SocialMediaIcon from "./SocialMediaIcon";
import IconTwitter
  from "./icon/Twitter_Social_Icon_Rounded_Square_Color.min.svg";
import IconDiscord from "./icon/Discord-Logo-Color.min.svg";
import IconReddit from "./icon/reddit_share_square_rounded_48.png";
import IconRSS from "../../../res/icon-material-design/material-io-rss-feed.svg";
import IconPatreon from "./icon/patreon-mark-coral.jpg";
import IconPatreonPatron from "./icon/patreon-become_a_patron_button@2x.png";
import IconMedium from "./icon/medium-monogram.min.svg";
import IconBlog from "../../../res/icon-material-design/material-io-chat.svg";
import { ROUTES } from "../../navigation/InternalRoute";

export const CHANNELS = {
  DISCORD: {
    name: "Discord",
    url: "https://discord.gg/ERh4ejj",
    icon: IconDiscord,
  },
  REDDIT: {
    name: "Reddit",
    url: "https://www.reddit.com/r/OberionIO",
    icon: IconReddit,
  },
  TWITTER: {
    name: "Twitter",
    url: "https://twitter.com/OberionIO",
    icon: IconTwitter,
  },
  PATREON: {
    name: "Patreon",
    url: "https://www.patreon.com/OberionIO",
    icon: IconPatreon,
  },
  PATREON_PATRON: {
    name: "Become a Patron",
    url: "https://www.patreon.com/OberionIO",
    icon: IconPatreonPatron,
  },
  MEDIUM: {
    name: "Medium",
    url: "https://medium.com/@OberionIO",
    icon: IconMedium,
  },
  RSS: {
    name: ROUTES.RSS.CAPTION,
    url: "",
    fullUrl: "",
    icon: IconRSS,
  },
  BLOG: {
    name: ROUTES.BLOG.CAPTION,
    url: ROUTES.BLOG.TO,
    fullUrl: `https://oberion.io${ROUTES.BLOG.TO}`,
    icon: IconBlog,
  },
};

const SocialMediaButton = ({ channel, unpadded }) => {
  let IconButtonToReturn = null;

  if (unpadded === true) {
    // unpadded => display the bare icons without extra styling

    if (channel.name === CHANNELS.BLOG.name || channel.name === CHANNELS.RSS.name) {
      // handle all internal routes
      IconButtonToReturn = (
        <GatsbyLink to={channel.url}>
          <SocialMediaIcon icon={channel.icon} caption={channel.name} />
        </GatsbyLink>
      );
    } else {
      // handle all external links
      IconButtonToReturn = (
        <a href={channel.url} target="_blank" rel="noopener" aria-label={channel.name}>
          <SocialMediaIcon icon={channel.icon} caption={channel.name} />
        </a>
      );
    }

  } else if (channel.name === CHANNELS.DISCORD.name) {
    /* --- Discord's icon has extra space therefore padding has to be adjusted --- */
    IconButtonToReturn = (
      <IconButton
        style={{ padding: "0.65rem" }} // Extrawurst for Discord
        aria-label={channel.name}
        href={channel.url} target="_blank" rel="noopener"
      >
        <SocialMediaIcon icon={channel.icon} caption={channel.name} />
      </IconButton>
    );

  } else {
    /* --- Padding for other icons --- */
    IconButtonToReturn = (
      <IconButton
        aria-label={channel.name}
        href={channel.url} target="_blank" rel="noopener"
      >
        <SocialMediaIcon icon={channel.icon} caption={channel.name} />
      </IconButton>
    );
  }
  return (
    IconButtonToReturn
  );
};

SocialMediaButton.defaultProps = {
  unpadded: false,
};
SocialMediaButton.propTypes = {
  channel: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  unpadded: PropTypes.bool,
};

export default SocialMediaButton;