import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { Grid, Typography } from "@material-ui/core";
import * as PropTypes from "prop-types";
import Logo from "../../element/graphical/Logo";
import theme, { footerBackground } from "../../element/theme";
import { InternalRoute, ROUTES } from "../InternalRoute";
import Newsletter from "./Newsletter"; // eslint-disable-line import/no-cycle
import SocialMediaButton, { CHANNELS } from "../../element/social-media/SocialMediaButton";
import { LINK_TO } from "../ExternalLink";


const copyrightText = `\u00A9 2019 Oberion by `;

const StickyBottomFooter = styled.footer`
  margin-top: auto;
`;
const FooterContainer = styled.footer`
  background: ${footerBackground};
  padding: 4rem 1rem;
  flex-grow: 1;
`;
const FooterNavLogo = styled.nav`
  justify-content: center;
  display: flex;
`;
const LegalTextDiv = styled.div`
  text-align: center;
  margin: 1rem 0;
  color: ${theme.palette.text.disabled};
`;
const FooterList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;
const FooterListIcon = styled.li`
  display: inline-block;
  margin: 0 0;
`;
const FooterListElement = styled.li`
  display: inline-block;
  margin: 0 1rem;
`;
const FooterLink = styled.a`
  text-decoration: underline;
  text-decoration-style: dotted;
  color: inherit;
  :hover {
    color: ${theme.palette.text.hint};
  }
  :active {
    color: ${theme.palette.text.hint};
  }
`;


const Footer = ({ hideNewsletter }) => {
  let newsletter = <Newsletter />;
  if (hideNewsletter) {
    newsletter = null;
  }

  return (
    <StickyBottomFooter>
      {newsletter}

      <FooterContainer>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FooterNavLogo>
              <Logo />
            </FooterNavLogo>
          </Grid>
          <Grid item xs={12}>
            <nav>
              <FooterList>
                <FooterListIcon>
                  <SocialMediaButton channel={CHANNELS.TWITTER} />
                </FooterListIcon>
                <FooterListIcon>
                  <SocialMediaButton channel={CHANNELS.RSS} />
                </FooterListIcon>
                <FooterListIcon>
                  <SocialMediaButton channel={CHANNELS.MEDIUM} />
                </FooterListIcon>
                {/*<FooterListIcon>*/}
                {/*  <SocialMediaButton channel={CHANNELS.REDDIT} />*/}
                {/*</FooterListIcon>*/}
                {/*<FooterListIcon>*/}
                {/*  <SocialMediaButton channel={CHANNELS.DISCORD} />*/}
                {/*</FooterListIcon>*/}
                {/*<FooterListIcon>*/}
                {/*  <SocialMediaButton channel={CHANNELS.PATREON} />*/}
                {/*</FooterListIcon>*/}
              </FooterList>
            </nav>
          </Grid>
          <Grid item xs={12}>
            <nav>
              <FooterList>
                <FooterListElement>
                  <InternalRoute caption={ROUTES.ABOUT.CAPTION} to={ROUTES.ABOUT.TO}
                                 variant="subtitle1" />
                </FooterListElement>
                <FooterListElement>
                  <InternalRoute caption={ROUTES.BLOG.CAPTION} to={ROUTES.BLOG.TO}
                                 variant="subtitle1" />
                </FooterListElement>
                <FooterListElement>
                  <InternalRoute caption={ROUTES.LEGAL.CAPTION} to={ROUTES.LEGAL.TO}
                                 variant="subtitle1" />
                </FooterListElement>
                {/* <FooterListElement>
                <ThemedLink caption="Terms of Use" to="/terms" />
              </FooterListElement> */}
                <FooterListElement>
                  <InternalRoute caption={ROUTES.PRIVACY.CAPTION} to={ROUTES.PRIVACY.TO}
                                 variant="subtitle1" />
                </FooterListElement>
              </FooterList>
            </nav>

          </Grid>
          <Grid item xs={12}>
            <LegalTextDiv>

              <Typography variant="caption" paragraph>
                <FooterLink
                  href={LINK_TO.steamStore} target="_blank" rel="noopener"
                >Powered by Steam
                </FooterLink>
                . Not affiliated with Valve. All trademarks are property of their
                respective owners.
              </Typography>

              <Typography variant="caption" paragraph>
                {copyrightText}
                <FooterLink as={GatsbyLink} to={ROUTES.ABOUT.TO}> Thomas Uta </FooterLink>
              </Typography>

            </LegalTextDiv>
          </Grid>
        </Grid>
      </FooterContainer>
    </StickyBottomFooter>
  );
};
Footer.propTypes = {
  hideNewsletter: PropTypes.bool,
};
Footer.defaultProps = {
  hideNewsletter: false,
};

export default Footer;
export { FooterLink, LegalTextDiv };
