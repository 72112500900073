import React from "react";
import styled from "styled-components";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import MailOutline from "@material-ui/icons/MailOutline";
import theme, { ooContentWidth } from "../../element/theme";
import NewsletterLegalText from "./NewsletterLegalText"; // eslint-disable-line import/no-cycle
import LoadingSpinner from "../../element/graphical/LoadingSpinner";
import { greaterOrEqualThan } from "../../util/mediaQuery";


const StyledValidatorForm = styled(ValidatorForm)`
  text-align: center;
  max-width: ${ooContentWidth}rem;
  margin: 4rem auto 3rem auto;
  padding: 1.5rem 10rem;
  ${greaterOrEqualThan.xs`padding: 1rem 1rem;`};
`;
const TextFieldButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 4.5rem;
`;
const StyledFormControlLabel = styled(FormControlLabel)`
  max-width: 26rem;
  text-align: left;
`;
const BorderFormControlLabel = styled(StyledFormControlLabel)`
  border: 1px solid ${theme.palette.error.main};
`;
const SubscribeButtonMargin = styled.div`
  margin-left: 2rem;
`;
const BigMailOutline = styled(MailOutline)`
  margin-bottom: -0.5rem;
  height: 2.5rem;
  width: 2.5rem;
`;
const StyledTextValidator = styled(TextValidator)`
  width: 100%;
  max-width: 16rem;
`;
const StyledGrid = styled(Grid)`
  margin-top: 1rem;
`;

const heading = "Newsletter ";
const buttonLabelSubscribe = "Subscribe";
const buttonLabelThanks = "Thanks!";
const checkBoxGdprLabel = " I would like to subscribe to monthly updates from Oberion and receive invites for exclusive early access to new features.";
const confirmSubscription = "One last step: Check your email inbox to confirm your subscription.";
const confirmCheckboxHint = "Please confirm that you want to subscribe by clicking the checkbox.";


class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.emailRef = React.createRef();
    this.state = {
      formData: {
        email: "",
      },
      isSubmitted: false,
      isGdprChecked: false,
      IsSubscribeClicked: false,
      mailchimpResponse: {
        msg: "",
        result: "",
      },
      confirmMailchimpError: "Something went wrong on our side. Please try again later while we fix this.",
      isLoading: false,
    };
  }

  handleCheckboxGdpr = () => {
    const { isSubmitted, isGdprChecked } = this.state;
    if (isSubmitted === false) {
      this.setState({ isGdprChecked: !isGdprChecked });
    }
  };

  handleSpinner = (isLoading) => {
    this.setState({ isLoading });
  };

  handleSubmit = async () => {
    // disabled when moved to startup.thomasuta.com
  };

  handleFormData = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleBlur = (event) => {
    this.emailRef.current.validate(event.target.value);
  };


  render() {
    const {
      formData,
      isSubmitted,
      isGdprChecked,
      IsSubscribeClicked,
      mailchimpResponse,
      confirmMailchimpError,
      isLoading,
    } = this.state;

    let subscriptionFeedback = null;
    let loadingSpinner = null;
    let GdprCheckbox = (
      <StyledFormControlLabel
        control={(
          <Checkbox
            checked={isGdprChecked}
            onChange={this.handleCheckboxGdpr}
            color="secondary"
          />
        )}
        label={checkBoxGdprLabel}
      />
    );

    // subscription not finished: show mailchimp error message
    if (mailchimpResponse.result === "error") {
      subscriptionFeedback = (
        <Typography color="error">
          {confirmMailchimpError}
        </Typography>
      );

      // subscription not finished: show GDPR error text, show GDPR checkbox with border
    } else if (isGdprChecked === false && isSubmitted === false && IsSubscribeClicked === true) {
      subscriptionFeedback = (
        <Typography color="error">
          {confirmCheckboxHint}
        </Typography>
      );

      GdprCheckbox = (
        <BorderFormControlLabel
          control={(
            <Checkbox
              checked={isGdprChecked}
              onChange={this.handleCheckboxGdpr}
              color="secondary"
            />
          )}
          label={checkBoxGdprLabel}
        />
      );

      // subscription finished: show confirm message, show GDPR checkbox without border
    } else if (isGdprChecked === true && isSubmitted === true && IsSubscribeClicked === true) {
      subscriptionFeedback = (
        <Typography color="textSecondary">
          {confirmSubscription}
        </Typography>
      );

      GdprCheckbox = (
        <StyledFormControlLabel
          control={(
            <Checkbox
              checked={isGdprChecked}
              onChange={this.handleCheckboxGdpr}
              color="secondary"
            />
          )}
          label={checkBoxGdprLabel}
        />
      );
    }

    if (isLoading) {
      loadingSpinner = <LoadingSpinner />;
    }

    /**
     * RETURN
     */
    return (
      <StyledValidatorForm
        onSubmit={this.handleSubmit}
        instantValidate={false}
        id="newsletter"
      >
        <Typography variant="h2" paragraph>
          {heading}
          <BigMailOutline color="secondary" />
        </Typography>

        <StyledGrid container spacing={3} justify="center" alignItems="flex-start">
          <Grid item sm={12} md={6}>

            {GdprCheckbox}

            <TextFieldButtonDiv>
              <StyledTextValidator
                disabled={isSubmitted}
                ref={this.emailRef}
                label="Email *"
                onBlur={this.handleBlur}
                onChange={this.handleFormData}
                name="email"
                value={formData.email}
                validators={["required", "isEmail"]}
                errorMessages={["This field is required", "Email is not valid"]}
              />

              <SubscribeButtonMargin>
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitted}
                  size="large"
                >
                  {(isSubmitted && buttonLabelThanks) || (!isSubmitted && buttonLabelSubscribe)}
                </Button>
              </SubscribeButtonMargin>
            </TextFieldButtonDiv>

            {loadingSpinner}
            {subscriptionFeedback}

          </Grid>

          <Grid item sm={12} md={6}>
            <NewsletterLegalText />
          </Grid>
        </StyledGrid>
      </StyledValidatorForm>
    );
  }
}

export default Newsletter;
