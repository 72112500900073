// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-backlog-challenge-jsx": () => import("./../src/pages/backlog-challenge.jsx" /* webpackChunkName: "component---src-pages-backlog-challenge-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-dev-test-jsx": () => import("./../src/pages/dev-test.jsx" /* webpackChunkName: "component---src-pages-dev-test-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-library-preview-jsx": () => import("./../src/pages/library-preview.jsx" /* webpackChunkName: "component---src-pages-library-preview-jsx" */),
  "component---src-pages-lists-celeste-jsx": () => import("./../src/pages/lists/celeste.jsx" /* webpackChunkName: "component---src-pages-lists-celeste-jsx" */),
  "component---src-pages-lists-hacknslash-915-jsx": () => import("./../src/pages/lists/hacknslash_915.jsx" /* webpackChunkName: "component---src-pages-lists-hacknslash-915-jsx" */),
  "component---src-pages-lists-motor-jsx": () => import("./../src/pages/lists/motor.jsx" /* webpackChunkName: "component---src-pages-lists-motor-jsx" */),
  "component---src-pages-lists-pinballboy-jsx": () => import("./../src/pages/lists/pinballboy.jsx" /* webpackChunkName: "component---src-pages-lists-pinballboy-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-settings-jsx": () => import("./../src/pages/settings.jsx" /* webpackChunkName: "component---src-pages-settings-jsx" */),
  "component---src-pages-steam-login-jsx": () => import("./../src/pages/steam-login.jsx" /* webpackChunkName: "component---src-pages-steam-login-jsx" */),
  "component---src-pages-thanks-jsx": () => import("./../src/pages/thanks.jsx" /* webpackChunkName: "component---src-pages-thanks-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */)
}

