import React from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import Header from "../../navigation/header/Header";
import Footer from "../../navigation/footer/Footer";

const PageContainer = styled.div`
  height: 100%;
  min-height: calc(100vh);
  
  display: flex; 
  flex-direction: column;
`;

/**
 * Fundamental layout which provides Header and Footer in a column style.
 */
const Layout = ({ children, hideNewsletter }) => (

  <PageContainer>
    <Header />

    {children}

    <Footer hideNewsletter={hideNewsletter} />
  </PageContainer>
);
Layout.defaultProps = {
  hideNewsletter: false,
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideNewsletter: PropTypes.bool,
};
export default Layout;
