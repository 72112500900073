import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import OberionLogo from "../../../res/branded/oberion/oberion-logo-white.png";
import { ROUTES } from "../../navigation/InternalRoute";


const Image = styled.img`
  box-sizing: border-box;
  display: block;
  height: 2.25rem;
  padding: 0.25rem;
  width: auto;
`;

const TransitionLink = styled.a`
  opacity: 1;
  transition: .5s ease;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.7;
  }
`;

const Logo = () => (
  <TransitionLink as={Link} to={ROUTES.HOME.TO}>
    <Image
      src={OberionLogo}
      alt="Oberion Logo"
    />
  </TransitionLink>
);

export default Logo;
