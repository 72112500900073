import React from "react";
import { Button } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import { ROUTES } from "../../navigation/InternalRoute";
import LayoutCosmos from "../../element/layout/LayoutCosmos";

const seo = {
  title: "400 Bad Request - Oberion",
  description: null,
};
const heading = "400 Bad Request";
const subheading = [
  "Your client issued a malformed or illegal request.",
  "We'll be fixing this bug.",
  "Until then there's time to explore more games 🚀",
];
const buttons = [
  <Button
    component={GatsbyLink}
    to={ROUTES.HOME.TO}
    variant="contained"
    color="secondary"
    fullWidth
  >Home
  </Button>
];

const BadRequest400 = () => (
  <LayoutCosmos seo={seo} heading={heading} subheading={subheading} buttons={buttons} />
);
export default BadRequest400;
