import React from "react";
import * as PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Drawer, IconButton } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import MenuIcon from "@material-ui/icons/Menu";
import { ButtonRoute, ROUTES } from "../InternalRoute";
import { navigationElements } from "./navigation-elements";

const styles = theme => ({
  listNav: {
    width: "auto",
    padding: "1rem 5rem",
    color: theme.palette.primary.contrastText,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class NavigationMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
    };
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;
    const { top } = this.state;

    const fullList = (
      <div className={classes.listNav}>
        <List component="ul">
          {navigationElements.map((element) => (
            <ButtonRoute as={GatsbyLink} to={element.to} key={element.caption}>
              <ListItem component="li" button divider>
                <ListItemText primary={element.caption} />
              </ListItem>
            </ButtonRoute>
          ))}
        </List>
      </div>
    );

    return (
      <>
        <IconButton color="inherit"
                    aria-label="Open drawer"
                    onClick={this.toggleDrawer("top", true)}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="top" open={top} onClose={this.toggleDrawer("top", false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("top", false)}
            onKeyDown={this.toggleDrawer("top", false)}
          >
            {fullList}
          </div>
        </Drawer>
      </>
    );
  }
}

NavigationMobile.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(NavigationMobile);