import axios from "axios";
import * as Sentry from "@sentry/browser";

const serverConfig = {
  openIdRealm: "",
  openIdReturnTo: "",
  HOST: "",
};
const localConfig = {
  openIdRealm: "",
  openIdReturnTo: "",
  HOST: "",
};
export const getOrigin = (key) => {
  return process.env.NODE_ENV === "production" ? serverConfig[key] : localConfig[key];
};
const HOST = getOrigin("HOST");

const axiosConfig = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
const thisFile = "steam-auth.js";


/** REST endpoints */
const STEAM_LOGIN_ENDPOINT = `${HOST}/api/steam/login`;
const STEAM_LOGOUT_ENDPOINT = `${HOST}/api/steam/logout`;
const STEAM_PROFILE_VISIBILITY_ENDPOINT = `${HOST}/api/steam/are-game-details-visible`;
const DELETE_USER_ENDPOINT = `${HOST}/api/delete-user`;


/** Log user IN via Steam account */
export const postSteamLogin = async (steamid) => {
  try {
    return await axios({
      method: "post",
      url: STEAM_LOGIN_ENDPOINT,
      data: { steamid: steamid },
      config: axiosConfig,
    });
  } catch (err) {
    Sentry.setExtra("file", thisFile);
    Sentry.setExtra("function", "postSteamLogin");
    Sentry.setExtra("parameters", [steamid]);
    Sentry.captureException(new Error(err));
    return null;
  }
};

/** Log user OUT */
export const postSteamLogout = async (steamid) => {
  try {
    return await axios({
      method: "post",
      url: STEAM_LOGOUT_ENDPOINT,
      data: { steamid: steamid },
      config: axiosConfig,
    });
  } catch (err) {
    Sentry.setExtra("file", thisFile);
    Sentry.setExtra("function", "postSteamLogout");
    Sentry.setExtra("parameters", [steamid]);
    Sentry.captureException(new Error(err));
    return null;
  }
};

/** Check if a user changed their Steam profile settings */
export const postSteamProfileVisibility = async (steamid) => {
  try {
    return await axios({
      method: "post",
      url: STEAM_PROFILE_VISIBILITY_ENDPOINT,
      data: { steamid: steamid },
      config: axiosConfig,
    });
  } catch (err) {
    Sentry.setExtra("file", thisFile);
    Sentry.setExtra("function", "postSteamProfileVisibility");
    Sentry.setExtra("parameters", [steamid]);
    Sentry.captureException(new Error(err));
    return null;
  }
};

/** Delete user account */
export const postDeleteUser = async (steamid) => {
  try {
    return await axios({
      method: "post",
      url: DELETE_USER_ENDPOINT,
      data: { steamid: steamid },
      config: axiosConfig,
    });
  } catch (err) {
    Sentry.setExtra("file", thisFile);
    Sentry.setExtra("function", "postDeleteUser");
    Sentry.setExtra("parameters", [steamid]);
    Sentry.captureException(new Error(err));
    return null;
  }
};