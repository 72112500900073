import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonRoute } from "../InternalRoute";
import { navigationElements } from "./navigation-elements";
import ooTheme from "../../element/theme";

export const headerButtonStyle = {
  button: {
    minWidth: "8rem",
    padding: "1rem 1rem",
    "&:hover": {
      backgroundColor: ooTheme.palette.primary.main,
    },
  },
  buttonTour: {
    minWidth: "11rem",
    padding: "1rem 1rem",
    "&:hover": {
      backgroundColor: ooTheme.palette.primary.main,
    },
  },
};

const useStyles = makeStyles({
  ...headerButtonStyle,
});

const DesktopNavigation = () => {
  const classes = useStyles();

  return (
    <>
      {navigationElements.map((element) => {
        if (element.displayInAppBar) {
          return (
            <ButtonRoute as={GatsbyLink} to={element.to} key={element.caption}>
              <Button color="inherit" className={classes.button} size="large">
                {element.caption}
              </Button>
            </ButtonRoute>
          );
        }
        return null;
      })}
    </>
  );
};
export default DesktopNavigation;