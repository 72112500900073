/**
 * Simple guide as implemented: https://www.toptal.com/react/react-context-api
 * Official API documentation: https://reactjs.org/docs/context.html
 */
import React from "react";
import * as PropTypes from "prop-types";

// noinspection JSCheckFunctionSignatures
export const AuthContext = React.createContext();

export class AuthContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steamid: null,
      authenticated: false,
      // gameDetailsVisible: false,
      accountDeleted: false,
      userObject: null,
    };
  }

  wipeContext = () => {
    this.setState({
      steamid: null,
      authenticated: false,
      // gameDetailsVisible: false,
      accountDeleted: false,
      userObject: null,
    });
  };


  logUserIn = () => {
    // console.log("logUserIn executed");
    this.setState({
      authenticated: true,
    });
  };
  logUserOut = () => {
    this.wipeContext();
  };

  isLoggedIn = () => {
    // console.log("User is logged in: "+this.state.authenticated);
    return this.state.authenticated;
  };

/*  setGameDetailsVisible = (isVisible) => {
    this.setState({ gameDetailsVisible: isVisible });
  };
  isGameDetailsVisible = () => {
    // console.log("User deleted account: "+this.state.accountDeleted);
    return this.state.gameDetailsVisible;
  };*/

  setAccountIsDeleted = () => {
    this.setState({ accountDeleted: true });
  };
  isAccountDeleted = () => {
    // console.log("User deleted account: "+this.state.accountDeleted);
    return this.state.accountDeleted;
  };

  getSteamid = () => {
    return this.state.steamid;
  };
  setSteamid = (steamid) => {
    // console.log(`setSteamid: ${steamid}`);
    this.setState({ steamid: steamid });
  };

  getUserObject = () => {
    // console.log("AuthContextProvider: getUserObject: ");
    // console.log(this.state.userObject);
    return this.state.userObject;
  };
  setUserObject = (userObject) => {
    // console.log("AuthContextProvider: setUserObject: ");
    // console.log(userObject);
    this.setState({ userObject: userObject });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          logUserIn: this.logUserIn,
          logUserOut: this.logUserOut,
          isLoggedIn: this.isLoggedIn,
          setAccountIsDeleted: this.setAccountIsDeleted,
          isAccountDeleted: this.isAccountDeleted,
          getSteamid: this.getSteamid,
          setSteamid: this.setSteamid,
          getUserObject: this.getUserObject,
          setUserObject: this.setUserObject,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

AuthContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};