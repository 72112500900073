import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { navigate } from "gatsby";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { ROUTES } from "../InternalRoute";
import { AuthContext } from "../../authentication/AuthContextProvider";
import { postSteamLogout } from "../../authentication/steam/steam-auth";

const buttonSize = "3rem";
const iconSize = "2.4rem";
const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: "inherit",
    width: buttonSize,
    height: buttonSize,
    padding: "1.5rem",

    // attributes for text
    // textAlign: "right",
    // margin: "0.325rem",
    // padding: "1.5rem",
  },
  avatarIcon: {
    width: iconSize,
    height: iconSize,
  },
  buttonSteam: {
    padding: "0.5rem 1rem",
    textTransform: "none",
  },
  buttonAvatar: {
    padding: "0",
  },
}));

export default function UserProfileButton() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authContext = useContext(AuthContext);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleSettings() {
    handleClose();
    navigate(ROUTES.SETTINGS_ACCOUNT.TO);
  }

  async function handleLogout() {
    handleClose();
    const response = await postSteamLogout(authContext.getSteamid);
    // console.log("postSteamLogout: Request sent. Response received: ");
    // console.log(response);
    // TODO wait for [@backend] confirmation before logout

    navigate(ROUTES.STEAM_LOGIN.LOGOUT,
      { state: { loggedOut: true, accountDeleted: false } });
    authContext.logUserOut();
  }

  return (
    <div>
      <Button
        aria-controls="user-dropdown" aria-haspopup="true" onClick={handleClick}
        className={classes.buttonAvatar}
      >
        <Avatar className={classes.avatar} component="div">
          <AccountCircleIcon className={classes.avatarIcon} />
        </Avatar>
      </Button>

      <Menu
        id="user-dropdown"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleSettings} button>{ROUTES.SETTINGS_ACCOUNT.CAPTION}</MenuItem>
        <MenuItem button onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}