import { ROUTES } from "../InternalRoute";

export const navigationElements = [
  {
    caption: ROUTES.HOME.CAPTION,
    to: ROUTES.HOME.TO,
    displayInAppBar: false,
  },
  {
    caption: "Tour",
    to: ROUTES.LISTS.CELESTE.TO,
    displayInAppBar: true,
  },
  {
    caption: ROUTES.LIBRARY.CAPTION,
    to: ROUTES.LIBRARY.TO,
    displayInAppBar: true,
  },
  {
    caption: "Backlog",
    to: ROUTES.BACKLOG_CHALLENGE.TO,
    displayInAppBar: true,
  },
  {
    caption: ROUTES.BLOG.CAPTION,
    to: ROUTES.BLOG.TO,
    displayInAppBar: true,
  },
  {
    caption: ROUTES.ABOUT.CAPTION,
    to: ROUTES.ABOUT.TO,
    displayInAppBar: true,
  },
];
