import React from "react";
import { Helmet } from "react-helmet";
import * as PropTypes from "prop-types";

/**
 * Provides meta tags for each page
 */
const SEO = ({ title, description }) => (
  <Helmet title={title}>

    <meta name="description" content={description} />

  </Helmet>
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  // image: PropTypes.string,
  // route: PropTypes.string,
};

SEO.defaultProps = {
  title: "Portfolio - Thomas Uta",
  description: null,
};
